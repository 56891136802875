import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { main_button } from '../app/components/Styles';
import { Add } from '@mui/icons-material';

export const Home = ({ user }) => {

    const navigate = useNavigate();

    return (
        <Box paddingX={isMobile ? 3 : 6}>
            <Grid container>
                <Grid item xs={isMobile ? 12 : 8}>
                    <Typography fontSize={isMobile ? 32 : 48}>
                        From <strong>efficient</strong> warehousing to <strong>innovative</strong> inventory solutions,
                        Chainz empowers your business to deliver—<strong>on time, every time.</strong>
                    </Typography>
                </Grid>
                <Grid item xs={4}/>
            </Grid>
            <Button sx={{...main_button, mt: 5}} onClick={() => navigate("/services")}>Learn More <Add/></Button>
        </Box>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
