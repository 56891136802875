import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Chip, Drawer, Grid, InputAdornment, InputLabel, NativeSelect, Select, Tooltip, styled } from '@mui/material';
import { MainInput, MainSearch, centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Logo from "./logo.png"
import Logo2 from "./logo2.png"
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountCircleOutlined, Analytics, ArrowForward, AttachMoney, Autorenew, Checkroom, ChevronLeft, Close, ExpandMore, Favorite, History, Home, Inventory, Key, KeyboardReturn, LocalShipping, Message, MonetizationOn, People, PriceCheck, QuestionAnswer, QuestionMark, RequestQuote, Search, Send, Storage, Store, Warehouse } from '@mui/icons-material';
import { Image } from 'antd';

function TopBar({ auth, logout, children }) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const user = auth?.user;
    const token = auth?.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const handleMenu = (event) => {
        setDrawerOpen(true)
    };

    const handleClose = () => {
        setDrawerOpen(false)
    };

    const handleLogin = () => {
        setDrawerOpen(false);
        route("/login")
    };

    const handleLogout = () => {
        setDrawerOpen(false);
        logout();
    };

    const handleHome = () => {
        navigate("/");
    };

    const isActive = (route) => {
        if (route === "/") {
            return !location.pathname.includes("/about") && !location.pathname.includes("/services") && !location.pathname.includes("/contact")
        } else {
            return location.pathname.includes(route)
        }
    };

    const route = (path) => {
        navigate(path);
        if (isMobile) {
            setDrawerOpen(false)
        }
    }


    const menuItems = <>
        <Box sx={{ ...right_flex_box }}>
            <IconButton onClick={handleClose} sx={{ backgroundColor: "var(--primaryColor) !important" }}><Close sx={{ color: "var(--secColor4)" }} /></IconButton>
        </Box>
        <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
            <MenuItem sx={{ color: "var(--secColor4)" }} onClick={() => route("/")}>{isActive("/") && "|"}<Typography fontWeight={600} marginX={2}>Home</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor4)" }} onClick={() => route("/services")}>{isActive("/services") && "|"}<Typography fontWeight={600} marginX={2}>Services</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor4)" }} onClick={() => route("/about")}>{isActive("/about") && "|"}<Typography fontWeight={600} marginX={2}>About</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor4)" }} onClick={() => route("/contact")}>{isActive("/contact") && "|"}<Typography fontWeight={600} marginX={2}>Contact</Typography></MenuItem>
        </Box>
    </>


    return (
        <Box sx={{ color: "var(--secColor)", paddingBottom: 9 }}>
            <BrowserView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" open={drawerOpen}>
                    <Toolbar sx={{ margin: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <Image preview={false} src={Logo} width={174} onClick={handleHome} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={3} sx={{ ...centered_flex_box }}>

                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <NavLink to={"/"}><Typography fontWeight={600} marginX={2}>Home</Typography></NavLink>
                                <NavLink to={"/services"}><Typography fontWeight={600} marginX={2}>Services</Typography></NavLink>
                                <NavLink to={"/about"}><Typography fontWeight={600} marginX={2}>About</Typography></NavLink>
                                <NavLink to={"/contact"}><Typography fontWeight={600} marginX={2}>Contact</Typography></NavLink>
                            </Grid>
                        </Grid>
                    </Toolbar >
                </AppBar >
            </BrowserView>
            <MobileView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar>
                        <Grid container mt={2}>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <Image width={112} height={"auto"} preview={false} src={Logo} onClick={handleHome} style={{ cursor: "pointer", padding: 5 }} />
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <IconButton onClick={handleMenu} sx={{ backgroundColor: "var(--appBg) !important" }}><MenuIcon sx={{ color: "var(--primaryColor)" }} /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"top"}>
                        <Drawer
                            anchor="top"
                            open={drawerOpen}
                            onClose={handleClose}
                        >
                            <Box sx={{ padding: 2, backgroundColor: "var(--primaryColor)" }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
                {children}
            </MobileView>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
