import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { centered_flex_box, main_button, MainFormControl, MainInput, MainRadioGroup } from '../app/components/Styles';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { notification } from 'antd';

export const Contact = ({ user }) => {

    const navigate = useNavigate();
    const [isLoading, setisLoading] = React.useState(false);
    const [isSuccessful, setIsSuccessful] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        setisLoading(true);
        axios.post("https://api.chainzsolutions.com/contact", {
            name: formData.get("name"),
            email: formData.get("email"),
            company: formData.get("company"),
            phone: formData.get("phone"),
            subject: formData.get("subject"),
            message: formData.get("message")
        }).then((response) => {
            const { data, status } = response
            if (status === 200) {
                setIsSuccessful(true)
            }
        }).catch((reason) => {
            notification.error({ message: reason })
        }).finally(() => {
            setisLoading(false)
        })
    }

    if (isSuccessful) {
        return (
            <Box paddingX={6} sx={{...centered_flex_box, flexDirection: "column", minHeight: "50vh"}}>
                <Typography fontWeight={600} gutterBottom={2} fontSize={isMobile ? 28 : 40} textAlign={"center"}>YOUR MESSAGE HAS BEEN RECEIVED!</Typography>
                <Typography textAlign={"center"} width={isMobile ? null : "50%"}>
                    Thank you for considering Chainz to optimize your logistics. We'll reach out soon to discuss how we can support your business goals with reliable and innovative solutions.
                </Typography>
            </Box>
        );
    }

    return (
        <Box paddingX={isMobile ? 3 : 6}>
            <Box sx={{ ...centered_flex_box, display: 'flex', alignItems: 'center', gap: isMobile ? 2 : 3 }}><Typography fontWeight={500} fontSize={isMobile ? 32 : 60}>{isMobile ? "CONTACT" : "GET IN TOUCH WITH"}</Typography><Typography fontWeight={500} whiteSpace="normal" fontStyle={"italic"} fontSize={isMobile ? 32 : 60}>CHAINZ</Typography></Box>
            <Typography fontWeight={500} fontSize={isMobile ? 20 : 24} textAlign={"center"}>WE'D LOVE TO HEAR FROM YOU!</Typography>
            <Box sx={{ ...centered_flex_box, mt: isMobile ? 2 : 10 }}>
                <Grid container component={"form"} onSubmit={handleSubmit} spacing={5} width={isMobile ? "90%" : "50%"}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MainInput
                            id='name'
                            name='name'
                            label="Full Name"
                            variant="standard"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MainInput
                            id='email'
                            name='email'
                            type='email'
                            label="Email"
                            variant="standard"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MainInput
                            id='company'
                            name='company'
                            label="Company Name"
                            variant="standard"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MainInput
                            id='phone'
                            name='phone'
                            label="Phone Number"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MainFormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Select Subject?</FormLabel>
                            <MainRadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="subject"
                                defaultValue={"Fulfillment"}
                            >
                                <FormControlLabel value="Warehousing" control={<Radio />} label="Warehousing" />
                                <FormControlLabel value="Fulfillment" control={<Radio />} label="Fulfillment" />
                                <FormControlLabel value="IMS" control={<Radio />} label="IMS" />
                                <FormControlLabel value="Full Package" control={<Radio />} label="Full Package" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                            </MainRadioGroup>
                        </MainFormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <MainInput
                            id='message'
                            name='message'
                            label="Message"
                            variant="standard"
                            multiline
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box }}>
                        <Button sx={{ ...main_button }} type='submit'>{isLoading ? <CircularProgress sx={{color: "var(--secColor4)", height: 20}} /> : "Send Message"}</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
