import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { centered_flex_box, main_button } from '../app/components/Styles';
import { Add, ArrowDownward, Inventory, KeyboardArrowDown } from '@mui/icons-material';
import BoxIcon from "../app/components/box.png";
import GearIcon from "../app/components/gear.png";
import ShippingIcon from "../app/components/shipping.png";
import { Image } from 'antd';

export const About = ({ user }) => {

    const navigate = useNavigate();
    const gridRef = React.useRef(null);
    const [scrollProgress, setScrollProgress] = React.useState(0);
    const [hoveredInfo, setHoveredInfo] = React.useState(null)

    const handleScroll = () => {
        if (!gridRef.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = gridRef.current;
        const progress = (scrollLeft / (scrollWidth - clientWidth)) * 100;
        setScrollProgress(progress);
    };

    React.useEffect(() => {
        const gridElement = gridRef.current;
        if (gridElement) {
            gridElement.addEventListener("scroll", handleScroll);
            return () => gridElement.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <Box paddingX={isMobile ? 3 : 6}>
            <Box sx={{ ...centered_flex_box, display: 'flex', alignItems: 'center', gap: isMobile ? 2 : 3 }}><Typography fontWeight={500} fontSize={isMobile ? 32 : 60}>ABOUT</Typography><Typography fontWeight={500} whiteSpace="normal" fontStyle={"italic"} fontSize={isMobile ? 32 : 60}>CHAINZ</Typography></Box>
            <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column" }}>
                <Typography textAlign={"center"} gutterBottom={2} fontWeight={600} fontSize={isMobile ? 20 : 46}>Linking Every Part of Your Chain</Typography>
                <Typography textAlign={"center"} width={isMobile ? "100%" : "30%"}>
                    Our Mission is to <strong>transform</strong> warehousing and fulfillment through <strong>transparency, reliability, and tech</strong> based solutions.
                </Typography>
                <Typography textAlign={"center"} mt={5} width={isMobile ? "100%" : "30%"}>
                    We are a warehousing company based in Egypt, committed to providing comprehensive logistics and supply chain solutions for businesses of all sizes. Established with a vision to revolutionize the supply chain industry, Chainz offers a range of services, from our inventory management system to efficient warehousing and cost-effective shipping solutions.
                </Typography>
                <Typography textAlign={"center"} my={5} width={isMobile ? "100%" : "30%"}>
                    KNOW MORE ABOUT US
                </Typography>
                <IconButton onClick={() => { document.getElementById('aboutus').scrollIntoView(); }}><KeyboardArrowDown /></IconButton>
                <hr
                    id="aboutus"
                    style={{
                        borderTop: "solid 1px var(--primaryColor)",
                        opacity: "100%",
                        width: "90%",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                />
            </Box>
            <Box sx={{ ...centered_flex_box, mt: 2, mb: 5, flexDirection: "column" }}>
                <Typography textAlign={"center"} fontWeight={600} mb={2} fontSize={isMobile ? 36 : 46} sx={{ color: "var(--primaryColor)" }}>OUR VALUES</Typography>
                <BrowserView>
                    <Grid container>
                        <Grid item xs={3} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 1 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(1) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Customer-Centric Service</Typography>
                            <Typography textAlign={"center"} width={"80%"}>We put our clients first and design our solutions around their needs.</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <hr
                                style={{
                                    borderTop: "solid 3px var(--secColor4)",
                                    opacity: "10%",
                                    width: "100%",
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 2 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(2) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Innovation in Every Step</Typography>
                            <Typography textAlign={"center"} width={"80%"}>From our tech-driven IMS to our efficient warehousing processes, we're always improving.</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <hr
                                style={{
                                    borderTop: "solid 3px var(--secColor4)",
                                    opacity: "10%",
                                    width: "100%",
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 3 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(3) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Integrity & Transparency</Typography>
                            <Typography textAlign={"center"} width={"80%"}>We believe in honest, clear communication and full visibility into your operations.</Typography>
                        </Grid>
                    </Grid>
                </BrowserView>
                <MobileView>
                    <Grid container>
                        <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 1 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(1) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Customer-Centric Service</Typography>
                            <Typography textAlign={"center"} width={"80%"}>We put our clients first and design our solutions around their needs.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr
                                style={{
                                    border: "none",
                                    height: "3px",
                                    opacity: "60%",
                                    backgroundColor: "var(--secColor4)",
                                    width: "1%",
                                    minHeight: "5vh",
                                    margin: "10px auto",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 2 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(2) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Innovation in Every Step</Typography>
                            <Typography textAlign={"center"} width={"80%"}>From our tech-driven IMS to our efficient warehousing processes, we're always improving.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <hr
                                style={{
                                    border: "none",
                                    height: "3px",
                                    opacity: "60%",
                                    backgroundColor: "var(--secColor4)",
                                    width: "1%",
                                    minHeight: "5vh",
                                    margin: "10px auto",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column", color: hoveredInfo && hoveredInfo === 3 ? "var(--primaryColor)" : null }} onMouseEnter={() => { setHoveredInfo(3) }} onMouseLeave={() => { setHoveredInfo(null) }}>
                            <Typography textAlign={"center"} fontWeight={600}>Integrity & Transparency</Typography>
                            <Typography textAlign={"center"} width={"80%"}>We believe in honest, clear communication and full visibility into your operations.</Typography>
                        </Grid>
                    </Grid>
                </MobileView>
                <Typography textAlign={"center"} fontWeight={600} my={2} fontSize={isMobile ? 36 : 46} sx={{ color: "var(--primaryColor)" }}>MEET THE TEAM</Typography>
                <MobileView>
                    <Grid container spacing={2} sx={{ ...centered_flex_box }}>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                    </Grid>
                </MobileView>
                <BrowserView>
                    <Grid container spacing={2} sx={{ ...centered_flex_box }}>
                        <Grid item xs={12/5} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12/5} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12/5} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12/5} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12/5} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                    </Grid>
                </BrowserView>
                <Typography textAlign={"center"} fontWeight={600} my={2} fontSize={isMobile ? 36 : 46} sx={{ color: "var(--primaryColor)" }}>OUR CLIENTS</Typography>
                <MobileView>
                    <Grid container spacing={2} sx={{ ...centered_flex_box }}>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "60vw", height: "60vw", backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                    </Grid>
                </MobileView>
                <BrowserView style={{maxWidth: "100%"}}>
                    <hr
                        style={{
                            border: "none",
                            height: "3px",
                            opacity: "100%",
                            background: `linear-gradient(90deg, var(--primaryColor) ${scrollProgress}%, var(--secColor1) ${scrollProgress}%)`,
                            width: "90%",
                            margin: "10px auto",
                        }}
                    />
                    <Grid container my={10} ref={gridRef} sx={{
                        overflowX: "auto",
                        maxWidth: "100%",
                        paddingBottom: 2,
                        display: "flex",
                        flexWrap: "nowrap"
                    }}>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                        <Grid item xs={2} sx={{ ...centered_flex_box }}>
                            <Box sx={{ width: "10vw", height: "10vw", marginRight: 2, backgroundColor: "var(--secColor1)" }}></Box>
                        </Grid>
                    </Grid>
                </BrowserView>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
