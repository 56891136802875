import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { centered_flex_box, main_button } from '../app/components/Styles';
import { Add, Inventory } from '@mui/icons-material';
import BoxIcon from "../app/components/box.png";
import GearIcon from "../app/components/gear.png";
import ShippingIcon from "../app/components/shipping.png";
import { Image } from 'antd';

export const Services = ({ user }) => {

    const navigate = useNavigate();
    const [hoveredCard, setHoveredCard] = React.useState(null)

    return (
        <Box paddingX={isMobile ? 3 : 6}>
            <Box sx={{ ...centered_flex_box, display: 'flex', alignItems: 'center', gap: isMobile ? 2 : 3 }}><Typography fontWeight={500} whiteSpace="normal" fontStyle={"italic"} fontSize={isMobile ? 32 : 60}>CHAINZ </Typography><Typography fontWeight={500} fontSize={isMobile ? 32 : 60}>SERVICES</Typography></Box>
            <Grid container mt={5}>
                <Grid item xs={isMobile ? 12 : 4} padding={isMobile ? 0 : 5} mb={isMobile ? 8 : 0} sx={{ opacity: hoveredCard && hoveredCard !== 1 ? "50%" : "100%" }}>
                    <div className="position-relative" onMouseEnter={() => setHoveredCard(1)} onMouseLeave={() => setHoveredCard(null)}>
                        <Box className="position-absolute top-0 start-50 translate-middle" sx={{ ...centered_flex_box, backgroundColor: "var(--secColor1)", width: 108, height: 76, borderRadius: 2 }}>
                            <Image src={BoxIcon} preview={false} />
                        </Box>
                        <Card sx={{ backgroundColor: "var(--secColor4)", height: isMobile ? null : 380 }}>
                            <CardContent sx={{ ...centered_flex_box, flexDirection: "column", mt: 5 }}>
                                <Typography gutterBottom={5} textAlign={"center"} fontWeight={500} fontSize={24}>Warehousing Solutions</Typography>
                                <Typography fontSize={16} textAlign={"center"}>Chainz provides flexible warehousing solutions to accommodate varying storage needs, ensuring the safety and integrity of your inventory. From short-term storage to long-term solutions, we tailor our services to meet the unique requirements of each client.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4} padding={isMobile ? 0 : 5} mb={isMobile ? 8 : 0} sx={{ opacity: hoveredCard && hoveredCard !== 2 ? "50%" : "100%" }}>
                    <div className="position-relative" onMouseEnter={() => setHoveredCard(2)} onMouseLeave={() => setHoveredCard(null)}>
                        <Box className="position-absolute top-0 start-50 translate-middle" sx={{ ...centered_flex_box, backgroundColor: "var(--secColor1)", width: 108, height: 76, borderRadius: 2 }}>
                            <Image src={GearIcon} preview={false} />
                        </Box>
                        <Card sx={{ backgroundColor: "var(--primaryColor)", height: (isMobile || hoveredCard === 2) ? null : 380, color: "var(--secColor4)" }}>
                            <CardContent sx={{ ...centered_flex_box, flexDirection: "column", mt: 5 }}>
                                <Typography gutterBottom={5} textAlign={"center"} fontWeight={500} fontSize={24}>Inventory Management System</Typography>
                                <Typography fontSize={16} textAlign={"center"}>Chainz offers an inventory management system that allows businesses to streamline their stock tracking processes. Our system leverages the latest technologies, providing real-time insights and inventory optimization tools. With Chainz, businesses can gain a competitive edge by efficiently managing their stock levels and reducing operational costs.</Typography>
                            </CardContent>
                            {(isMobile || hoveredCard === 2) && <CardContent>
                                <Typography fontSize={18} fontWeight={600}>Feature List :</Typography>
                                <Typography paddingLeft={2}>
                                    <li><strong>Real-time</strong> inventory visibility</li>
                                    <li>Advanced <strong>tracking</strong> and forecasting</li>
                                    <li><strong>Customizable</strong> reporting</li>
                                    <li>Simple setup and <strong>user-friendly interface</strong></li>
                                </Typography>
                            </CardContent>}
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4} padding={isMobile ? 0 : 5} mb={isMobile ? 8 : 0} sx={{ opacity: hoveredCard && hoveredCard !== 3 ? "50%" : "100%" }}>
                    <div className="position-relative" onMouseEnter={() => setHoveredCard(3)} onMouseLeave={() => setHoveredCard(null)}>
                        <Box className="position-absolute top-0 start-50 translate-middle" sx={{ ...centered_flex_box, backgroundColor: "var(--secColor1)", width: 108, height: 76, borderRadius: 2 }}>
                            <Image src={ShippingIcon} preview={false} />
                        </Box>
                        <Card sx={{ backgroundColor: "var(--secColor4)", height: isMobile ? null : 380 }}>
                            <CardContent sx={{ ...centered_flex_box, flexDirection: "column", mt: 5 }}>
                                <Typography gutterBottom={5} textAlign={"center"} fontWeight={500} fontSize={24}>Shipping Services</Typography>
                                <Typography fontSize={16} textAlign={"center"}>Chainz offers nationwide shipping services with a commitment to reliability, efficiency, and cost-effectiveness. Our logistics team in partnership with reliable shipping companies ensure that your products reach their destination on time and in pristine condition.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
