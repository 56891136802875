import React from "react";
import "./App.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import TopBar from "../src/app/components/TopBar";
import BackToTop from 'react-back-to-top';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BottomBar } from "./app/components";
import ScrollToTop from "./app/components/RouteToTop";

//ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <TopBar />
          <Router />
          <ScrollToTop />
          <BackToTop
            mainStyle={{
              "border-radius": 30,
              "background-color": "var(--primaryColor) !important"
            }}
            children={<KeyboardArrowUpIcon sx={{ color: "var(--appBg) !important" }} />}
            isPercent={false}
            offsetTop={0}
            step={50} />
          <BottomBar className="BottomBar" />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
